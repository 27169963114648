import React from 'react';
import { navigate } from 'gatsby';
import { View, Text, Link, ButtonWithLoading } from '../../components/common';
import Layout from '../../components/layout';
import Loading from '../../components/svg/AnimatedLoading';
import { SMALL } from '../../components/content';
import config from '../../config';
import { colors } from '../../style';
import { differenceInDays } from 'date-fns';
import * as util from '../../account-util';

// This library is lazily imported when the page loads
let loadJS;

function hasSubscription(userData) {
  let { status } = userData;
  return status === 'subscribed' || status === 'pending_payment';
}

function BillingInfo({ userData, loading, onSubscribe }) {
  let daysLeft = userData.trialEnd
    ? Math.max(differenceInDays(new Date(userData.trialEnd), new Date()), 0)
    : null;

  if (!hasSubscription(userData)) {
    let msg;

    if (userData.status === 'trial') {
      msg = (
        <Text className="text-center text-lg text-teal-600">
          There are <strong>{daysLeft} days</strong> left on your trial.
        </Text>
      );
    } else if (userData.status === 'trial_ended') {
      msg = (
        <Text className="text-center">
          <strong className="text-teal-600 text-lg">
            Your trial has ended.
          </strong>
        </Text>
      );
    } else if (userData.status === 'cancelled') {
      msg = (
        <Text className="text-center">
          <strong className="text-teal-600 text-lg">
            Your subscription has been cancelled.
          </strong>
        </Text>
      );
    }

    return (
      <>
        {msg}
        <ButtonWithLoading
          primary
          className="self-center mt-2 mb-4"
          loading={loading}
          onClick={onSubscribe}
        >
          Subscribe now!
        </ButtonWithLoading>
      </>
    );
  } else {
    let msg;

    if (userData.status === 'pending_payment') {
      msg = (
        <Text className="text-red-600 font-bold text-center mx-8">
          We had a problem with your latest payment. Please update your payment
          method. Your subscription will be cancelled after 2 weeks.
        </Text>
      );
    }

    return (
      <>
        {msg}
        <ButtonWithLoading
          primary
          className="self-center mt-2 mb-4"
          loading={loading}
          onClick={() => {
            if (userData.revenuecatProductId) {
              alert(
                'Your subscription is managed through your mobile device. ' +
                  'Go to Apple or Google Play on your device to manage it.'
              );
            } else {
              window.location.href = userData.stripePortalUrl;
            }
          }}
        >
          Manage Subscription
        </ButtonWithLoading>
      </>
    );
  }
}

async function normalizeResponse(res) {
  let contentType = res.headers.get('Content-Type');
  if (contentType.toLowerCase().indexOf('application/json') !== -1) {
    return res.json();
  }

  if (res.status !== 200) {
    return {
      status: 'error',
      reason: await res.text(),
      statusCode: res.status
    };
  }
  return { status: 'ok', data: await res.text() };
}

class AccountPage extends React.Component {
  state = {
    email: '',
    planType: null,
    stripeData: null,
    loadingStripe: false,
    error: null
  };

  async componentDidMount() {
    if (!util.getTempId()) {
      navigate('/account/login/', { replace: true });
    } else {
      this.fetchAccount();
    }

    loadJS = (await import('load-js')).default;
  }

  fetchAccount = async () => {
    let id = util.getTempId();

    let res = await fetch(config.endpoint + '/account-with-details', {
      method: 'POST',
      body: JSON.stringify({ tempId: id }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    res = await normalizeResponse(res);

    if (!util.handleError(res)) {
      if (res.status === 'error') {
        this.setState({ error: res.reason });
      } else {
        let userData = res.data;

        if (this.fixingInvalidAccount) {
          // We never want to recurse multiple times; this guards
          // against an infinite loop
          this.setState({ error: 'internal' });
          return;
        } else if (!userData.stripeId && !userData.revenuecatProductId) {
          // There is no attached payment service. This is an account
          // that hasn't fully been created yet, so create Stripe and
          // re-fetch the account.
          this.fixingInvalidAccount = true;

          res = await fetch(config.endpoint + '/create-stripe', {
            method: 'POST',
            body: JSON.stringify({ tempId: id }),
            headers: {
              'Content-Type': 'application/json'
            }
          });
          res = await normalizeResponse(res);

          if (res.status === 'error') {
            this.setState({ error: 'internal' });
            return;
          } else {
            return this.fetchAccount();
          }
        } else {
          this.setState({ userData });
        }
      }
    }
  };

  onSubscribe = async ({ isUpdate }) => {
    if (!loadJS) {
      alert('An error occurred. Please try again');
      return;
    }

    if (!this.state.userData.customer) {
      alert(
        'Something is wrong with your account. Please contact help@actualbudget.com for support'
      );
      return;
    }

    this.setState({ error: null, loadingStripe: true });
    await loadJS([
      { async: true, url: 'https://checkout.stripe.com/checkout.js' }
    ]);

    let handler = window.StripeCheckout.configure({
      key: config.stripeKey,
      image: 'https://actualbudget.com/icon.png',
      locale: 'auto',
      zipCode: true,
      email: this.state.userData.email,
      token: async token => {
        let res = await fetch(config.endpoint + '/add-source', {
          method: 'POST',
          body: JSON.stringify({ tempId: util.getTempId(), token: token.id }),
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => res.json());

        if (!util.handleError(res)) {
          if (res.status === 'error') {
            console.log('got', res);
            this.setState({ error: res.reason, loadingStripe: false });
          } else {
            this.setState({ loadingStripe: true });
            await this.fetchAccount();
            this.setState({ loadingStripe: false });
          }
        }
      },
      opened: () => {},
      closed: () => {
        this.setState({ loadingStripe: false });
      }
    });

    handler.open({
      name: 'Actual',
      description: 'Monthly subscription ($4/month)',
      panelLabel: isUpdate ? 'Update Card' : 'Subscribe'
    });
  };

  onSignOut = e => {
    e.preventDefault();
    sessionStorage.removeItem('tempId');
    navigate('/account/login/');
  };

  renderError(error) {
    let msg;
    switch (error) {
      case 'card-declined':
        msg =
          'Your card was declined. Try again later or use a different payment method.';
        break;
      case 'card-error':
        msg = 'There was an error adding your payment information.';
        break;
      default:
        msg = (
          <Text>
            An error occurred. Please email{' '}
            <Link to="mailto:help@actualbudget.com">help@actualbudget.com</Link>{' '}
            for help.
          </Text>
        );
    }

    return <Text className="link-color-inherit mb-8 text-red-700">{msg}</Text>;
  }

  render() {
    let { userData, loadingStripe, error } = this.state;

    console.log('got', error);

    return (
      <Layout>
        <View className="items-center mt-16">
          {!userData ? (
            error ? (
              <Text className="link-color-inherit mb-8 text-red-700">
                An error occurred. Please email{' '}
                <Link to="mailto:help@actualbudget.com">
                  help@actualbudget.com
                </Link>{' '}
                for support.
              </Text>
            ) : (
              <Loading style={{ width: 20, height: 20 }} color={colors.n1} />
            )
          ) : (
            <View
              style={{
                width: 500,
                backgroundColor: 'white',
                borderRadius: 4,
                [SMALL]: {
                  width: 'auto',
                  padding: 15
                }
              }}
            >
              <View className="mb-8 md:flex-row">
                <Text className="text-xl font-bold">
                  Account: {userData.email}
                </Text>
                <View style={{ flex: 1 }} />
                <Link to="#" onClick={this.onSignOut}>
                  Sign out
                </Link>
              </View>

              {error && this.renderError(error)}

              <BillingInfo
                userData={userData}
                loading={loadingStripe}
                onSubscribe={this.onSubscribe}
              />

              <View
                className="block text-right link-color-inherit text-gray-600 mt-8 text-sm"
                style={{
                  '& a, & a:visited, & a:active': { textDecoration: 'none' }
                }}
              >
                <Link external to="https://download.actualbudget.com/download">
                  Download Actual
                </Link>{' '}
              </View>
            </View>
          )}
        </View>
      </Layout>
    );
  }
}

export default AccountPage;
